ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") " ";
}

.main {
    background-size: 400% 400%;
    height: 100vh;
    background: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);;
}

.mainImg {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    border-radius: 25px;
}
